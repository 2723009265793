import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",

  base: process.env.BASE_URL,
  routes: [
    { path: "/", component: () => import("@/pages/home") },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/pages/auth/Login"),
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/pages/auth/Register"),
    },
    {
      path: "/auth/confirm/:id/:hash",
      name: "Verify Email",
      component: () => import("@/pages/auth/Confirm"),
    },
    {
      path: "/",
      component: () => import("@/pages/dashboard/Index"),
      meta: {
        requiresAuth: true,
      },
      children: [
        // Dashboard
        {
          name: "dashboard.page title",
          path: "dashboard",
          component: () => import("@/pages/dashboard/Dashboard"),
        },
        // Changelog
        {
          name: "project.changelog",
          path: "changelog",
          component: () => import("@/pages/changelog/Changelog"),
        },
        // Pages
        {
          name: "profile.page title",
          path: "pages/user",
          component: () => import("@/pages/users/UserProfile"),
        },
        {
          name: "tickets.page title",
          path: "pages/tickets",
          meta: {
            permission: "answer tickets",
          },
          component: () => import("@/pages/tickets/TicketsTable"),
        },
        {
          name: "tickets.edit",
          path: "pages/tickets/:uid",
          meta: {
            permission: "answer tickets",
          },
          component: () => import("@/pages/tickets/TicketsForm"),
        },
        {
          name: "users.page title",
          path: "pages/users",
          meta: {
            permission: "edit users",
          },
          component: () => import("@/pages/users/UsersTable"),
        },
        {
          name: "clients.page title",
          path: "pages/clients",
          meta: {
            permission: "edit clients",
          },
          component: () => import("@/pages/clients/ClientsTable"),
        },
        {
          name: "projects.page title",
          path: "pages/projects",
          meta: {
            permission: "edit projects",
          },
          component: () => import("@/pages/projects/ProjectsTable"),
        },
        {
          name: "quizzes.page title",
          path: "pages/quizzes",
          meta: {
            permission: "edit quizzes",
          },
          component: () => import("@/pages/quizzes/QuizzesTable"),
        },
        {
          name: "quizzes.edit",
          path: "pages/quizzes/:id",
          meta: {
            permission: "edit quizzes",
          },
          component: () => import("@/pages/quizzes/QuizzesForm"),
        },
        {
          name: "roles.page title",
          path: "/pages/roles",
          meta: {
            permission: "edit roles",
          },
          component: () =>
            import("@/pages/users/roles-permissions/RolesAndPermissions"),
        },
        {
          name: "notifications.page title",
          path: "pages/notifications",
          component: () => import("@/pages/users/Notifications"),
        },
        {
          name: "configurator.page title",
          path: "pages/configurator",
          component: () => import("@/pages/configurator/Configurator"),
        },
        {
          name: "Debug routes",
          path: "/debug/routes",
          component: () => import("@/pages/debug/Routes"),
        },
        {
          name: "Debug route",
          path: "/debug/routes/:method/:uri",
          component: () => import("@/pages/debug/Route"),
        },
      ],
    },
    { path: "*", component: () => import("@/pages/home") },
  ],
});

function hasPermission(permissions, permission) {
  if (typeof permission === "string") {
    return permissions.includes(permission);
  } else {
    for (const i in permission) {
      if (permissions.includes(permission[i])) {
        return true;
      }
    }
    return false;
  }
}

router.beforeEach((to, from, next) => {
  let loggedInUser = localStorage.getItem("loggedInUser");
  if (loggedInUser == "null") {
    loggedInUser = null;
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    if (!loggedInUser) {
      const loginpath = window.location.pathname;
      next({ name: "Login", query: { from: loginpath } });
    } else if (loggedInUser) {
      loggedInUser = JSON.parse(loggedInUser);
      if (
        to.meta.permission &&
        !hasPermission(loggedInUser.permissions, to.meta.permission)
      ) {
        next(from);
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
