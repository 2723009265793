module.exports = {
  ...require("./base.json"),
  validation: require("./validation.json"),
  register: require("./register.json"),
  project: require("./project.json"),
  Sample: require("./_sample.json"),
  clients: require("./clients.json"),
  projects: require("./projects.json"),
  tickets: require("./tickets.json"),
  quizzes: require("./quizzes.json"),
  configurator: require("./configurator.json"),
  know: require("./know.json"),
  //GEN
  roles: require("./roles.json"),
  settings: require("./settings.json"),
  notifications: require("./notifications.json"),
  dashboard: require("./dashboard.json"),
  profile: require("./profile.json"),
  users: require("./users.json"),
  reset: require("./reset.json"),
  subscription: require("./subscription.json"),
};
