<template>
  <v-text-field
    v-model="color"
    hide-details
    :label="label"
    @click="menu = true"
  >
    <template v-slot:append>
      <v-menu
        v-model="menu"
        top
        left
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="color"
              mode="hexa"
              hide-mode-switch
              flat
            ></v-color-picker>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    color: {
      get() {
        return this.value;
      },
      set(color) {
        this.$emit("input", color);
      },
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-top: 0;
  margin-bottom: 0;
}
</style>
