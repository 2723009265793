import Vue from "vue";
import VueI18n from "vue-i18n";

import en from "vuetify/lib/locale/en";
import de from "vuetify/lib/locale/de";

Vue.use(VueI18n);

const messages = {
  en: {
    ...require("@/locales/en/all.js"),
    $vuetify: {
      ...en,
      ...require("@/locales/en/vuetify.json"),
    },
  },
  de: {
    ...require("@/locales/de/all.js"),
    $vuetify: {
      ...de,
      ...require("@/locales/de/vuetify.json"),
    },
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "de",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "de",
  messages,
});
