import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const hasPermission = (permissions, permission) => {
  if (typeof permission === "string") {
    return permissions.includes(permission);
  } else {
    for (const i in permission) {
      if (permissions.includes(permission[i])) {
        return true;
      }
    }
    return false;
  }
};

// import notificationMixin from "mixins/notifications";

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    barColor: "rgba(21, 21, 24, 1), rgba(21, 21, 24, .8)",
    // barImage: require("@/assets/lock.jpg"),
    showBarImage: false,
    theme: "light",
    primaryColor: "#685EC0",
    drawer: null,
    rolesAndPermissions: {
      newPermissionAdded: false,
      permissions: [],
    },
    notifications: [],
    totalNotifications: 0,
    lastNotification: 0,
    projectId: null,
    ticket: "",
  },
  mutations: {
    SET_THEME(state, payload) {
      state.theme = payload;
      if (payload == "light") {
        state.barColor = "rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)";
      } else {
        state.barColor = "rgba(0, 0, 0, .8), rgba(0, 0, 0, .7)";
      }
    },
    SET_COLOR(state, payload) {
      state.primaryColor = payload;
    },
    SET_PROJECT(state, payload) {
      state.projectId = payload;
    },
    SET_BAR_IMAGE(state, payload) {
      if (payload) {
        state.barImage = payload;
        state.showBarImage = true;
      } else {
        state.showBarImage = false;
      }
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
    SET_LANG(state, locale) {
      if (state.locales.includes(locale)) {
        state.locale = locale;
      }
    },
    SET_LOGIN_STATUS(state, payload) {
      state.loginSts = payload;
    },
    SET_LOGIN_DATA(state, payload) {
      state.loginData = payload;
      localStorage.setItem("loggedInUser", JSON.stringify(payload));
      if (state.loginData != null) {
        if (hasOwnProperty.call(state.loginData, "security_settings")) {
          for (const index in state.loginData.security_settings) {
            state.auth2Buttons[index].type =
              state.loginData.security_settings[index];
          }
          /* Set security */
          if (
            hasOwnProperty.call(state.loginData.security_settings, "security")
          ) {
            state.hasSecurity = state.loginData.security_settings.security;
          } else {
            state.hasSecurity = null;
          }
          /* Set send security */
          if (hasOwnProperty.call(state.loginData.security_settings, "send")) {
            state.hasSendSecurity = state.loginData.security_settings.send;
          } else {
            state.hasSendSecurity = null;
          }
        }
      }
    },
    LOGIN_ERROR(state, payload) {
      state.loginErrors = payload;
    },
    SET_NEW_PERMISSION_ADDED(state, payload) {
      state.rolesAndPermissions.newPermissionAdded = payload;
    },
    SET_PERMISSIONS_ARRAY(state, payload) {
      state.rolesAndPermissions.permissions = payload;
    },
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = payload.latest;
      state.totalNotifications = payload.total;
      state.lastNotification = payload.tstamp;
    },
    UPDATE_NOTIFICATIONS(state, payload) {
      for (let i in payload.latest) {
        state.notifications.push(payload.latest[i]);
      }
      state.totalNotifications = payload.total;
      state.lastNotification = payload.tstamp;
    },
  },
  actions: {},
  getters: {
    menuHasPermission: (state) => (menu) => {
      if (menu.permission) {
        if (!state.loginData) {
          const loggedInUser = localStorage.getItem("loggedInUser");
          if (!loggedInUser || loggedInUser == "null") {
            return false;
          }
          state.loginData = JSON.parse(loggedInUser);
        }
        return hasPermission(state.loginData.permissions, menu.permission);
      }

      return true;
    },
    getClient: (state) => {
      return state.apiClient || localStorage.getItem("apiClient");
    },
    getPermissionsArray: (state) => {
      const data = {};
      state.rolesAndPermissions.permissions.map(
        (e) => (data[e.id] = { name: e.name, guard_name: e.guard_name })
      );
      return data;
    },
  },
});
