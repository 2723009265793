import qs from "qs";

export default {
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          fetchConfig: {
            // fetch options for api requests
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            redirect: "manual", // manual, *follow, error
            // referrerPolicy: 'no-referrer', // no-referrer, *client
          },
          sending: false,
          apiUrl: options.url,
        };
      },
      computed: {
        loggedInUser() {
          let user = null;
          if (process.browser) {
            user = localStorage.getItem("loggedInUser");
            if (user == "null") {
              user = null;
            }
          }
          if (!user) return null;
          return JSON.parse(user);
        },
      },
      methods: {
        async apiLogin(form = this.form) {
          this.sending = true;
          const res = await fetch(this.apiUrl + "/login", {
            ...this.fetchConfig,
            method: "POST",
            body: JSON.stringify(form),
          });
          this.sending = false;
          if (res.ok) {
            localStorage.setItem("tempMe", JSON.stringify(form.email));
            this.loginReady(res);
          } else {
            const error = await res.json();
            throw error;
          }
        },

        async apiRegistration(form = this.form) {
          this.sending = true;
          const res = await fetch(this.apiUrl + "/register", {
            ...this.fetchConfig,
            method: "POST",
            body: JSON.stringify(form),
          });
          this.sending = false;
          if (res.ok) {
            this.loginReady(res);
          } else {
            const error = await res.json();
            throw error;
          }
        },

        async loginReady(res) {
          let data = await res.json();
          this._saveAuthorizationToken(data);
          this.fetchConfig.headers.Authorization =
            "Bearer " + data.access_token;

          if (hasOwnProperty.call(data, "auth2f")) {
            this.$router.push("/login2");
          } else {
            /* Get profile data after login */
            res = await fetch(this.apiUrl + "/me", this.fetchConfig);
            if (res.ok) {
              data = await res.json();
              data.auth = 1;
              this.$store.commit("SET_LOGIN_STATUS", true);
              this.$store.commit("SET_LOGIN_DATA", data);
              this.$i18n.locale = data.locale;
              this.sending = false;
              this.$router.push("/");
              return data;
            }
            this.sending = false;
            const error = await res.json();
            throw error;
          }
        },

        _saveAuthorizationToken(data) {
          localStorage.setItem("accessToken", data.access_token);
          localStorage.setItem("refreshToken", data.refresh_token);
          window.refreshing_token = false;
          let date = new Date();
          date = new Date(date.getTime() + parseInt(data.expires_in) * 1000);
          localStorage.setItem("tokenExpires", date);
        },

        async waitForRefreshToken() {
          function sleep(time) {
            return new Promise((resolve) => setTimeout(resolve, time));
          }

          while (window.refreshing_token) {
            if (!window.refreshing_token) {
              break;
            }
            await sleep(100);
          }
        },

        async _addAuthorizationToken(headers) {
          try {
            const token = localStorage.getItem("accessToken");
            const expires = localStorage.getItem("tokenExpires");
            if (token && expires) {
              const expireTime = new Date(expires);
              const timeLeft =
                (expireTime.getTime() - new Date().getTime()) / 1000;
              if (timeLeft < 0) {
                this._handleUnauthenticated();
                return;
              }
              if (timeLeft < 1200 && localStorage.getItem("refreshToken")) {
                if (window.refreshing_token) {
                  await this.waitForRefreshToken();
                  headers.headers.Authorization =
                    "Bearer " + localStorage.getItem("accessToken");
                  return headers;
                }
                window.refreshing_token = true;
                headers.headers.Authorization = "Bearer " + token;
                const res = await fetch(this.apiUrl + "/refresh", {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                  },
                  method: "POST",
                  body: JSON.stringify({
                    refresh_token: localStorage.getItem("refreshToken"),
                  }),
                });

                if (res.ok) {
                  const data = await res.json();
                  if (data.access_token) {
                    this._saveAuthorizationToken(data);
                    headers.headers.Authorization =
                      "Bearer " + data.access_token;
                  }
                  return headers;
                } else {
                  this._handleUnauthenticated();
                  return;
                }
              } else {
                headers.headers.Authorization = "Bearer " + token;
                return headers;
              }
            } else {
              this._handleUnauthenticated();
            }
          } catch (e) {
            this._handleUnauthenticated();
          }
        },

        fetch_no_auth(url, headers) {
          this.sending = true;
          return new Promise((resolve, reject) => {
            fetch(url, headers)
              .then((res) => {
                if (res.ok) {
                  res
                    .json()
                    .then((data) => {
                      this.sending = false;
                      resolve(data);
                    })
                    .catch(() => {
                      this.sending = false;
                      resolve(null);
                    });
                } else {
                  res
                    .json()
                    .then((error) => {
                      this.sending = false;
                      reject(error);
                    })
                    .catch((ex) => {
                      this.sending = false;
                      reject(ex);
                    });
                }
              })
              .catch((error) => {
                this.sending = false;
                reject(error);
              });
          });
        },

        fetch(url, headers) {
          this.sending = true;
          return new Promise((resolve, reject) => {
            this._addAuthorizationToken(headers).then((fullHeaders) => {
              fetch(url, fullHeaders)
                .then((res) => {
                  if (res.ok) {
                    res
                      .json()
                      .then((data) => {
                        this.sending = false;
                        resolve(data);
                      })
                      .catch(() => {
                        this.sending = false;
                        resolve(null);
                      });
                  } else {
                    if (res.status === 401) {
                      this._handleUnauthenticated();
                    }
                    res
                      .json()
                      .then((error) => {
                        this.sending = false;
                        reject(error);
                      })
                      .catch((ex) => {
                        this.sending = false;
                        reject(ex);
                      });
                  }
                })
                .catch((error) => {
                  this.sending = false;
                  reject(error);
                });
            });
          });
        },

        _handleUnauthenticated() {
          this.sending = false;
          window.refreshing_token = false;
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("loggedInUser");
          this.$router.push("/login");
          this.$store.commit("SET_LOGIN_STATUS", false);
          this.$store.commit("SET_LOGIN_DATA", null);
        },

        get(url, params, method = "GET", auth = true) {
          if (auth) {
            return this.fetch(this.apiUrl + url + "?" + qs.stringify(params), {
              ...this.fetchConfig,
              method,
            });
          } else {
            return this.fetch_no_auth(
              this.apiUrl + url + "?" + qs.stringify(params),
              {
                ...this.fetchConfig,
                method,
              }
            );
          }
        },

        post(url, params, method = "POST", auth = true) {
          if (auth) {
            return this.fetch(this.apiUrl + url, {
              ...this.fetchConfig,
              method,
              body: JSON.stringify(params),
            });
          } else {
            return this.fetch_no_auth(this.apiUrl + url, {
              ...this.fetchConfig,
              method,
              body: JSON.stringify(params),
            });
          }
        },

        upload(url, params, method = "POST") {
          let conf = JSON.parse(JSON.stringify(this.fetchConfig));
          delete conf.headers["Content-Type"];
          return this.fetch(this.apiUrl + url, {
            ...conf,
            method,
            body: params,
          });
        },

        download(url, filename) {
          const headers = {
            ...this.fetchConfig,
            method: "GET",
          };
          this.sending = true;
          return new Promise((resolve, reject) => {
            this._addAuthorizationToken(headers).then((fullHeaders) => {
              fetch(this.apiUrl + url, fullHeaders)
                .then((res) => {
                  if (res.ok) {
                    let anchor = document.createElement("a");
                    res.blob().then((blobby) => {
                      let objectUrl = window.URL.createObjectURL(blobby);

                      anchor.href = objectUrl;
                      anchor.download = filename;
                      anchor.click();

                      window.URL.revokeObjectURL(objectUrl);
                    });
                  } else {
                    if (res.status === 401) {
                      this._handleUnauthenticated();
                    }
                    res
                      .json()
                      .then((error) => {
                        this.sending = false;
                        reject(error);
                      })
                      .catch((ex) => {
                        this.sending = false;
                        reject(ex);
                      });
                  }
                })
                .catch((error) => {
                  this.sending = false;
                  reject(error);
                });
            });
          });
        },

        put(url, params) {
          return this.post(url, params, "PUT");
        },

        patch(url, params) {
          return this.post(url, params, "PATCH");
        },

        delete(url, id = "", params = null) {
          return this.fetch(this.apiUrl + url + id + "?" + qs.stringify(params), {
            ...this.fetchConfig,
            method: "DELETE",
          });
        },
      },
    });
  },
};
