<template>
  <figure v-lazyload class="image__wrapper">
    <img class="image__item" :data-url="src" :alt="alt" />
    <ImageSpinner class="image__spinner" />
  </figure>
</template>

<style scoped>
.image__wrapper {
  min-height: 100px;
}
.loaded .image__spinner {
  display: none;
}
</style>
<script>
import ImageSpinner from "./ImageSpinner.vue";
export default {
  components: { ImageSpinner },
  name: "ImageItem",
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
    },
  },
};
</script>
