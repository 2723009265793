import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
// Translation provided by Vuetify (typescript)
import '@/sass/overrides.sass'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  mounted() {
    this.$vuetify.lang.defaultLocale = 'de'
    this.$vuetify.lang.current = 'de'
  },
  theme: {
    themes: {
      dark: {
        primary: '#685EC0',
        secondary: '#9C27B0',
        accent: '#9C27B0',
        info: '#00CAE3',
        anchor: '#3F51B5'
      },
      light: {
        primary: '#685EC0',
        secondary: '#9C27B0',
        accent: '#9C27B0',
        info: '#00CAE3',
        anchor: '#3F51B5'
      },
    },
  },
})
