export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        alert(message, type='error') {
          if (typeof message == 'string') {
            window.eventBus.$emit("alert", { text: message, type:type });
          } else {
            window.eventBus.$emit("alert", message);
          }
        },
      },
    });
  },
};
