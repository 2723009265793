<template>
  <v-alert v-bind="$attrs" dark v-on="$listeners">
    <template v-if="$attrs.icon" v-slot:prepend>
      <v-icon
        class="v-alert__icon elevation-6 white"
        light
        :color="$attrs.color"
      >
        {{ $attrs.icon }}
      </v-icon>
    </template>

    <slot />

    <template v-slot:close="{ toggle }">
      <v-btn :title="$t('notifications.read')" color icon small @click="toggle">
        <v-icon>mdi-check-circle</v-icon>
      </v-btn>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: "Notification",
};
</script>

<style lang="sass">

.v-alert--material
  margin-top: 10px
</style>
