import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
import "./plugins/chartist";
// import './plugins/vee-validate'
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import LazyLoadDirective from "./directives/LazyLoadDirective";

import "material-design-icons-iconfont/dist/material-design-icons.css";

import api from "./plugins/api-fetch";
import alert from "./plugins/alert";

Vue.use(api, {
  url: "/api",
});

Vue.use(alert);
Vue.config.productionTip = false;

Vue.directive("lazyload", LazyLoadDirective);

import VueCodeHighlight from "vue-code-highlight";

Vue.use(VueCodeHighlight); //registers the v-highlight directive

window.eventBus = new Vue({
  router,
  store,
  vuetify,
  i18n,
  api,
  alert,
  render: (h) => h(App),
}).$mount("#app");
